import React from "react";
import Layout from "../components/common/Layout";
import HeroSection from "../components/common/HeroSection";
import ProjectCard from "../components/projects/ProjectCard";
import { useOngoingList } from "../components/projects/OngoingList";

function OngoingProjectsPage() {
  const data = useOngoingList();
  const projects = data.allOngoingJson.edges;

  return (
    <Layout>
      <HeroSection
        title="Residential Ongoing Projects"
        backgroundImage={
          projects[0] ? projects[0].node.banner.childImageSharp.fluid.src : null
        }
      />
      <section className="section-pad border-bottom">
        <div className="container">
          <div className="row  justify-content-center">
            {projects.map(({ node }) => (
              <div className="col-lg-4 mb-3" key={node.id}>
                <ProjectCard {...node} />
              </div>
            ))}
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default OngoingProjectsPage;
